var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "box_1" },
    [
      _c(
        "div",
        { staticClass: "form_login", attrs: { disabled: _vm.codeValid } },
        [
          _vm._m(0),
          _c("PasswordVerificationForm", {
            attrs: {
              redirectLoginPage: true,
              showTextVersion: true,
              userLogin: _vm.login,
            },
          }),
        ],
        1
      ),
      _c("Carousel"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          "justify-content": "flex-start",
          "align-content": "center",
          "align-items": "center",
        },
      },
      [
        _c("img", {
          attrs: {
            src: require("@/assets/icons/retextoar-app.png"),
            width: "35px",
            height: "35px",
          },
        }),
        _c("p", { staticClass: "titleStyle" }, [_vm._v("Retextoar")]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }