var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "carousel" },
    [
      _c(
        "b-carousel",
        {
          staticStyle: { "text-shadow": "1px 1px 2px #333" },
          attrs: {
            id: "carousel-1",
            interval: 4000,
            controls: "",
            indicators: "",
          },
          on: {
            "sliding-start": _vm.onSlideStart,
            "sliding-end": _vm.onSlideEnd,
          },
          model: {
            value: _vm.slide,
            callback: function ($$v) {
              _vm.slide = $$v
            },
            expression: "slide",
          },
        },
        _vm._l(_vm.carouselItems, function (item) {
          return _c("b-carousel-slide", {
            key: item.id,
            attrs: { caption: item.text, "img-src": item.image },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }